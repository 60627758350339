

/*--------------Nav--------------*/
#navbar{
  width: 99vw;
  min-height: 10vh;
  margin: auto;
  text-align: center;
}
.navLi{
  display: block;
  text-align: center;
  font-size: 1.5vw;
  font-weight: 100;
  letter-spacing: 3px;
  font-family: "test", Arial, Helvetica, sans-serif;
  padding-top: 1.1vh;
  color: #fff;
  text-decoration: none;
}
#navbar div div{
  border: 2px rgb(103, 0, 199) solid;
  background-image: linear-gradient(310deg, rgba(14, 6, 136,0.6) 0%, rgba(34, 31, 75, 0.6) 72%);
  width: 25vh;
  height: 6vh;
  margin: 3vh;
  display: inline-block;
  text-align: center;
  border-radius: 15px;
  transition: 1.5s;

}
#navbar div div:hover{
  border: 3px rgb(190, 0, 248) solid;
  background-image: linear-gradient(310deg, rgba(150, 21, 21, 0.6) 0%, rgba(0, 21, 211, 0.6) 72%);
  
}
.navActive{
  height: 100%;
}
.navDeactive{
  height: 0;
}
.naviBtn{
  width: 5vh;
  height: 5vh;
}
#showNavibtn{
  display: none;
}
#hideNavibtn{
  display: none;
}
@media(max-width:1200px)
{
    #showNavibtn{
      display: block;
      position: absolute;
      right: 0;
      margin-right: 5vw;
      margin-top: 2vh;
      -webkit-filter: invert(90%);
    }
    #hideNavibtn{
      display: inline-block;
      margin-left: 5vw;
      margin-top: 2vh;
      border-radius: 100px;
      -webkit-filter: invert(90%);
    }
    .navLi{
      display: block;
      text-align: left;
      font-size: 5.5vw;
      
      font-weight: 100;
      letter-spacing: 3px;
      font-family: "test", Arial, Helvetica, sans-serif;
      padding-top: 0;
      color: #fff;
      text-decoration: none;
    }
    .nav-links{
        position:absolute;
        background:rgb(55, 0, 107);
        border-left: 3px solid rgb(103, 0, 199);
        height: 0;
        width: 50vw;
        top: 0;
        text-align: left;
        right: 0;
        z-index: 2;
        transition: 1s;
        overflow: hidden;
    }
    #navbar div div{
      border: 0;
      background-image: none;
      display: block;
    
    }
    #navbar div div:hover{
      border: 0;
      background-image: none;
      
    }
}

/*--------------Header--------------*/
#header{
  margin-top: 8vh;
  width: 99vw;
  min-height: 80vh;
  text-align: center;
  font-family: "test";
}
#header h1{
  color: rgb(2, 0, 37);
  font-size: 9vw;
  text-shadow: -4px 4px 8px rgb(194, 0, 253),
				  4px 4px 8px rgb(129, 0, 250),
				  4px -4px 0 rgb(103, 0, 199),
				  -4px -4px 0 rgb(103, 0, 199);
  letter-spacing: 1vh;
  animation: fadeInUp; 
  animation-duration: 2s; 
}
#header h2{
  font-size: 2.5vw;
  text-shadow: -1px 1px 2px rgb(194, 0, 253),
				  1px 1px 2px rgb(129, 0, 250),
				  1px -1px 0 rgb(152, 0, 199),
				  -1px -1px 0 rgb(152, 0, 199);
  letter-spacing: 1vh;
  animation: fadeInUpBig; 
  animation-duration: 3s; 
  
}
#headerLogo{
  width: 20%;
  height: 20%;
  border-radius: 100%;
  border:rgb(103, 0, 199) solid 5px;
  -webkit-box-shadow: 0px 0px 100px -3px rgb(103, 0, 199);
  -moz-box-shadow: 0px 0px 100px -3px rgb(103, 0, 199);
  box-shadow: 0px 0px 100px -3px rgb(103, 0, 199);
}

@media(max-width:1200px)
{
  #headerLogo{
    width: 30vh;
    height: 30vh;
  }
  #header h2{
    font-size: 5vw;
  }
}
/*--------------MainText--------------*/
#mainText{
  width: 99vw;
  min-height: 70vh;
}
.mainTextBlock{
  width: 65vw;
  height: 35vh;
  background-color: rgb(71, 0, 138);
  margin: auto;
  margin-bottom: 3vh;
  border:rgb(103, 0, 199) solid 5px;
  -webkit-box-shadow: 0px 0px 40px -3px rgb(103, 0, 199);
  -moz-box-shadow: 0px 0px 40px -3px rgb(103, 0, 199);
  box-shadow: 0px 0px 40px -3px rgb(103, 0, 199);
}
.MainTextImageGroup{
  width: 35%;
  height: 100%;
  text-align: center;
}
.mainTextContent{
  float: right;
  width: 65%;
  height: 100%;
  color: white;
  font-size: 2.5vh;
  font-family: "test", Arial, Helvetica, sans-serif;
  letter-spacing: 1px;
  
}
.mainTextContent p{
  padding: 20px 0;
  margin-left: 4vh;
  margin-right: 2vh;
  font-weight: 200;
}
.mainTextImage{
  width: 15vw;
  height: 15vw;
  border-radius: 100%;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  
}
#mainTextBlock1, #mainTextBlock3{
  border-radius: 15px 50px 15px 50px;
  margin-left: 10vw;
}
#mainTextBlock1 .MainTextImageGroup, #mainTextBlock3 .MainTextImageGroup{
  float: left;
  position: relative;
}
#mainTextBlock1 .mainTextContent, #mainTextBlock3 .mainTextContent{
  float: right;
}

#mainTextBlock2{
  border-radius: 50px 15px 50px 15px;
  margin-right: 10vw;
}

#mainTextBlock2 .MainTextImageGroup{
  float: right;
  position: relative;
}
#mainTextBlock2 .mainTextContent{
  float: left;
  
}
.mainTextContentH1{
  border: 10px solid;
  border-image-slice: 1;
  border-width: 3px;
  border-image-source: linear-gradient(90deg, rgba(71,0,138,1) 0%, rgb(91, 0, 177) 25%, rgba(71,0,138,1) 75%);
  border-left: 0;
  border-top: 0;
  border-right: 0;
  margin-bottom: 1vh;
  font-weight: 400;
}
@media only screen and (max-width: 1200px)
{
  #mainTextBlock1 .MainTextImageGroup,#mainTextBlock2 .MainTextImageGroup, #mainTextBlock3 .MainTextImageGroup,   #mainTextBlock1 .mainTextContent,#mainTextBlock2 .mainTextContent, #mainTextBlock3 .mainTextContent{
    clear: both;
  }
  .MainTextImageGroup{
    width: 100%;
    height: 25%;
  }
  .mainTextContent{
    width: 100%;
    height: 75%;
  }
  .mainTextBlock{
    min-height: 80vh;
    width: 80%;
  }
  .mainTextImage{
    width: 15vh;
    height: 15vh;
  }
  .mainTextContent p{
    padding: 0;
    font-size: 2vh;
  }
}
@media only screen and (min-device-width: 900px) and (max-device-width: 1500px)
{
  .mainTextBlock{
    min-height: 40vh;
    width: 80%;
    margin-bottom: 7vh;
  }
  .mainTextImage{
    width: 20vh;
    height: 20vh;
    border:rgb(103, 0, 199) solid 5px;
    -webkit-box-shadow: 0px 0px 40px -3px rgb(103, 0, 199);
    -moz-box-shadow: 0px 0px 40px -3px rgb(103, 0, 199);
    box-shadow: 0px 0px 40px -3px rgb(103, 0, 199);
  }
  .MainTextImageGroup{
    margin-bottom: 5vh;
  }
}



/*--------------Contact--------------*/
#contact{
  width: 99vw;
  min-height: 40vh;
  text-align: center;
  margin-bottom: 10vh;
  margin-top: 10vh;
  
}
.contactBlocks{
  display: inline-block;
  width: 30vw;
  margin: 0 2vh;
  height: 40vh;
  background-color: rgb(55, 0, 107);
  border:rgb(103, 0, 199) solid 1px;
  -webkit-box-shadow: 0px 0px 20px -3px rgb(103, 0, 199);
  -moz-box-shadow: 0px 0px 20px -3px rgb(103, 0, 199);
  box-shadow: 0px 0px 20px -3px rgb(103, 0, 199);
}
.contactImageBlocks{
  width: 30%;
  height: 100%;
  float: left;
  position: relative;
  background-color: rgb(55, 0, 107);
}
.contactImageBlocks img{
  width: 80%;
  height: auto;
  -webkit-filter: invert(99%);
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.contactContentBlocks{
  float: right;
  background-color: rgb(71, 0, 138);
  width: 70%;
  height: 100%;
}
.contactContentBlocks h2{
  width: 100%;
  height: 20%;
  font-size: 5vh;
  margin-top: 1vh;
  color: white;
  font-family: "test", Arial, Helvetica, sans-serif;
  letter-spacing: 3px;
}
.contactContentBlocks p{
  padding: 0 2vh;
  padding-top: 0;
  font-size: 2.5vh;
  width: 100%;
  height: 80%;
  color: white;
  font-family: "test", Arial, Helvetica, sans-serif;
  letter-spacing: 3px;
}
#contactSocialsImages{
  width: 100%;
  height: 100%;
}
.contactSocialIco{
  width: 7vh;
  height: 7vh;
  margin: 1vh;
}
.contactHref{
  text-decoration: none;
  color: white;
}
@media(max-width:1200px)
{
  .contactBlocks{
    display: inline-block;
    width: 80vw;
    margin: 2vh 2vh;
    height: 55vh;
    background-color: white;
  }
  .contactContentBlocks p{
    font-size: 2vh;
  }
  
}

/*--------------Content--------------*/
#content{
  display: block;
  width: 99vw;
  min-height: 1vh;
  margin: 5vh 0 5vh 0;
  font-family: "test", Arial, Helvetica, sans-serif;
  color: white;
  letter-spacing: 2.5px;
}
#contentBox{
  margin: auto;
  display: block;
  width: 50vw;
  min-height: 1vh;
  background-color: rgb(55, 0, 107);
  border-radius: 15px;
  border:rgb(103, 0, 199) solid 1px;
  -webkit-box-shadow: 0px 0px 20px -3px rgb(103, 0, 199);
  -moz-box-shadow: 0px 0px 20px -3px rgb(103, 0, 199);
  box-shadow: 0px 0px 20px -3px rgb(103, 0, 199);
}
#contentBox legend{
  text-align: center;
  font-size: 2.5vw;
  border:rgb(103, 0, 199) solid 1px;
  -webkit-box-shadow: 0px 0px 20px -3px rgb(103, 0, 199);
  -moz-box-shadow: 0px 0px 20px -3px rgb(103, 0, 199);
  box-shadow: 0px 0px 20px -3px rgb(103, 0, 199);
  border-radius: 5px;
  background-color: rgb(55, 0, 107);
  padding: 12px;
}
.contentPolecamDiv{
  width: 100%;
  min-height: 20%;
  border: 1px solid rgb(103, 0, 199);
  border-top: none;
  border-radius: 0 0 15px 15px;
}
.contentPolecamH1{
  width: 30%;
  min-height: 1px;
  margin: 3%;
  display: inline-block;
  vertical-align: middle;
  font-size: 2vw;
  border-right: 1px solid rgb(103, 0, 199);
}
.contentPolecamH1 a{
  text-decoration: none;
  color: white;
}
.contentPolecamP{
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  width: 60%;
  min-height: 1px;
  font-size: 1.1vw;
}
@media(max-width:1200px)
{
  #contentBox{
    width: 90vw;
  }
  #contentBox legend{
    font-size: 3.5vh;
  }
  .contentPolecamH1{
    font-size: 2.5vh;
    margin-left: 20px;
    width: 40%;
  }
  .contentPolecamP{
    font-size: 2vh;
    width: 50%;
    margin: 2vh 0 2vh 0;
  }
}

/*--------------App--------------*/
#appVideo{
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -100;
  object-fit: cover;
}
#app{
  background-color: rgba(0, 0, 0,0.5);
}
html { scroll-behavior: smooth; }

/*--------------Footer--------------*/
#footer{
  width: 99vw;
  min-height:5vh;
  background-color: rgb(55, 0, 107);
  text-align: center;
  border-top: 2px solid rgb(103, 0, 199);
}
#footerP{
  color: white;
  font-size: 2vh;
  padding: 1vh;
}

/*--------------Gallery--------------*/
#galleryDiv{
  width: 60vw;
  min-height: 60vh;
  margin: auto;
  text-align: center;
  background-color: rgb(71, 0, 138);
  border-radius: 15px;
  border:rgb(103, 0, 199) solid 3px;
  -webkit-box-shadow: 0px 0px 40px -3px rgb(103, 0, 199);
  -moz-box-shadow: 0px 0px 40px -3px rgb(103, 0, 199);
  box-shadow: 0px 0px 40px -3px rgb(103, 0, 199);
}
#galleryHeaderContainer{
  width: 100%;
  height: 8vh;
  background-color: rgb(55, 0, 107);
  border-radius: 15px;
  border-bottom:rgb(103, 0, 199) solid 2px;
  -webkit-box-shadow: 0px 0px 40px -3px rgb(103, 0, 199);
  -moz-box-shadow: 0px 0px 40px -3px rgb(103, 0, 199);
  box-shadow: 0px 0px 40px -3px rgb(103, 0, 199);
}
.galleryBtnShape{
  display: inline-block;
  width: 10vw;
  height: 7.8vh;
  background-color: rgb(37, 0, 71);
  border-radius: 15px;
  cursor: pointer;
  transition: 1s;
}
.galleryBtnShape1{
  float: left;
  position: relative;
}
.galleryBtnShape1::before{
  transition: 1s;
  content: "";
    position: absolute;
    bottom: 0; 
    left: 9.5vw; 
    width: 0;
    height: 0;
    border-top: 7.9vh solid transparent;
    border-bottom: 7.9vh solid rgb(37, 0, 71); 
    border-right: 10vh solid transparent; 
}
.galleryBtnShape2{
  float: right;
  position: relative;
}
.galleryBtnShape2::before{
  transition: 1s;
  content: "";
  position: absolute;
  top: 0;
  left: -4.5vw;
  width: 0;
  height: 0;
  border-left: 10vh solid transparent;
  border-right: 7.9vh solid transparent;
  border-top: 7.9vh solid rgb(37, 0, 71);
}
#galleryH2{
  display: inline-block;
  font-size: 5vh;
  color: white;
  font-family: "test", Arial, Helvetica, sans-serif;
  letter-spacing: 2.5px;
  margin-top: 1vh;
}
.galleryChangeButtons{
  margin: 2.5vh;
  font-size: 2.5vh;
  letter-spacing: 2px;
  font-family: "test", Arial, Helvetica, sans-serif;
  font-weight: 100;
  color: white;
}
.galleryActiveBtn1{
  background-color: rgb(103, 0, 199);
}
.galleryActiveBtn1::before{
    content: "";
    position: absolute;
    bottom: 0; 
    left: 9.5vw; 
    width: 0;
    height: 0;
    border-top: 7.9vh solid transparent;
    border-bottom: 7.9vh solid rgb(103, 0, 199); 
    border-right: 10vh solid transparent; 
}
.galleryActiveBtn2{
  background-color: rgb(103, 0, 199);
}
.galleryActiveBtn2::before{
  content: "";
  position: absolute;
  top: 0;
  left: -4.5vw;
  width: 0;
  height: 0;
  border-left: 10vh solid transparent;
  border-right: 7.9vh solid transparent;
  border-top: 7.9vh solid rgb(103, 0, 199);
}
.galleryImageSource{
  width: 12vw;
  height: 8.4375vw;
  margin: 2vh;
  object-fit: cover;
  border:rgb(103, 0, 199) solid 2px;
  -webkit-box-shadow: 0px 0px 40px -3px rgb(103, 0, 199);
  -moz-box-shadow: 0px 0px 40px -3px rgb(103, 0, 199);
  box-shadow: 0px 0px 40px -3px rgb(103, 0, 199);
}
.galleryImageSource:hover
{
    transform: scale(2);
    padding: 2px;
    transition: 0.5s;
}
.galleryImageDivSource{
  display: inline-block;
}

.galleryDisplay{
    animation-name: myAnimationShow;
    animation-duration: 1000ms;
    animation-fill-mode: forwards;
}
.galleryNotDisplay{
    animation-name: myAnimationVanish;
    animation-duration: 1000ms;
    animation-fill-mode: forwards;
}
.galleryNotDisplayVideo{
  display: none;
}

@keyframes myAnimationVanish{
  0% { opacity: 1; }
  100% { opacity: 0; display: none; }
}
@keyframes myAnimationShow{
  0% { opacity: 0; }
  100% { opacity: 1; display: inline-block; }
}

@media(max-width:1200px)
{
  #galleryDiv{
    width: 90vw;
  }
  .galleryImageSource{
    width: 26vw;
    height: 18.2813vw;
    margin: 1vh;
  }
  .galleryImageSource:hover{
    transform: scale(2.6);
    padding: 2px;
    transition: 0.5s;
  }
  #galleryH2{
    font-size: 9vw;
    margin-top: 1vh;
  }
  .galleryBtnShape2::before{
    display: none;
  }
  .galleryBtnShape1::before{
    display: none;
  }
  .galleryChangeButtons{
    margin: 0;
    font-size: 4vw;
    letter-spacing: 1.5px;
    margin-top: 3vh;
  }
  .galleryBtnShape{
    width: 20vw;
  }
}




/*--------------Font--------------*/
@font-face {
  font-family: "test";
  src: url("./Fonts/corn.ttf") format("truetype");
}
